import PropTypes from 'prop-types'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Carousel } from 'react-responsive-carousel'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import '../styles/pages/_index.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const IndexPage = ({
  data: {
    datoCmsHomePage: homePage,
    datoCmsWashspacesPage,
    datoCmsOurOceanPage,
    datoCmsAboutPage
  }
}) => {
  return (
    <Layout>
      <HelmetDatoCms seo={homePage.seoMetaTags} />
      <Carousel showArrows={false} showThumbs={false} autoPlay infiniteLoop swipeable interval={4000}>
        {homePage.heroSlider.map(item => (
          <section
            key={item.id}
            className="hero is-large is-fullpage-hero has-carousel"
            style={{ backgroundImage: `url(${item.backgroundImage.fixed.src})` }}
          >
            <div className="hero-body">
              <div className="container">
                <h1 className="title has-text-white has-text-weight-normal is-lowercase">
                  {!item.link && item.title}
                  {item.link && <Link to={item.link} className="has-text-white has-text-weight-normal is-lowercase">{item.title}</Link>}
                </h1>
              </div>
            </div>
          </section>
        ))}
      </Carousel>
      <section className="section">
        <div className="container">
          <div className="content content-home">
            {ReactHtmlParser(homePage.strapline)}
          </div>
        </div>
      </section>
      <Link
        to={'/projects'}
        className="hero is-large has-hero-background is-home-hero-primary"
        style={{
          backgroundImage: `url(${
            datoCmsWashspacesPage.heroBackgroundImage.fixed.src
          })`
        }}
      >
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title is-size-2 has-text-white">projects</h1>
          </div>
        </div>
      </Link>
      <div className="columns" style={{ backgroundColor: 'black' }}>
        <div className="column is-half has-text-centered">
          <Link
            to={'/resources'}
            className="hero is-large has-hero-background is-home-hero-secondary"
            style={{
              backgroundImage: `url(${
                datoCmsOurOceanPage.heroBackgroundImage.fixed.src
              })`
            }}
          >
            <div className="hero-body">
              <h1 className="title is-size-2 has-text-white">resources</h1>
            </div>
          </Link>
        </div>
        <div className="column is-half has-text-centered">
          <Link
            to={'/about'}
            className="hero is-large has-hero-background is-home-hero-secondary"
            style={{
              backgroundImage: `url(${
                datoCmsAboutPage.heroBackgroundImage.fixed.src
              })`
            }}
          >
            <div className="hero-body">
              <h1 className="title is-size-2 has-text-white">about us</h1>
            </div>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    datoCmsHomePage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroSlider {
        id
        title
        link
        backgroundImage {
          alt
          fixed(
            width: 1920
            imgixParams: { auto: "compress", fm: "pjpg", crop: "entropy" }
          ) {
            src
          }
        }
      }
      strapline
    }
    datoCmsWashspacesPage {
      heroTitle
      heroBackgroundImage {
        fixed(
          width: 1920
          imgixParams: { auto: "compress", fm: "pjpg", crop: "entropy" }
        ) {
          src
        }
      }
    }
    datoCmsOurOceanPage {
      heroTitle
      heroBackgroundImage {
        fixed(
          width: 800
          imgixParams: {
            auto: "compress"
            fm: "pjpg"
            crop: "entropy"
            bri: -10
          }
        ) {
          src
        }
      }
    }
    datoCmsAboutPage {
      heroTitle
      heroBackgroundImage {
        fixed(
          width: 800
          imgixParams: { auto: "compress", fm: "pjpg", crop: "entropy" }
        ) {
          src
        }
      }
    }
  }
`
